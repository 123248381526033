.transaction-list-container {
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 16px;
}

.transaction-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.transaction-item {
  padding: 12px;
  background-color: #2a2a2a;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.transaction-item:hover {
  background-color: #333333;
}

.transaction-text {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  font-size: 14px;
  flex-wrap: wrap;
}

.solana-icon {
  display: flex;
  align-items: center;
}

.solana-icon img {
  width: 16px;
  height: 16px;
}

.wallet-name, .token-link, .transaction-link, .wallet-link {
  color: #5d80f9;
  text-decoration: none;
  font-weight: 500;
}

.wallet-name:hover, .token-link:hover, .transaction-link:hover, .wallet-link:hover {
  text-decoration: underline;
}

em {
  color: #808080;
  font-style: normal;
}

.wallet-short {
  color: #808080;
  font-size: 12px;
}

.transaction-link {
  margin-left: auto;
}

/* Pagination Controls */
.transaction-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 12px;
  background-color: #2a2a2a;
  border-radius: 8px;
}

.items-per-page {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #808080;
}

.items-per-page select {
  background-color: #3a3a3a;
  color: white;
  border: 1px solid #4a4a4a;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.items-per-page select:hover {
  border-color: #5d80f9;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 15px;
}

.page-button {
  background-color: #3a3a3a;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.page-button:hover:not(:disabled) {
  background-color: #4a4a4a;
}

.page-button:disabled {
  background-color: #2a2a2a;
  cursor: not-allowed;
  opacity: 0.5;
}

.page-info {
  color: #808080;
  min-width: 120px;
  text-align: center;
}

.jump-to-page {
  display: flex;
  gap: 8px;
  align-items: center;
}

.jump-to-page input {
  background-color: #3a3a3a;
  color: white;
  border: 1px solid #4a4a4a;
  padding: 5px 10px;
  border-radius: 4px;
  width: 60px;
  text-align: center;
}

.jump-to-page input:focus {
  outline: none;
  border-color: #5d80f9;
}

.jump-to-page input::-webkit-inner-spin-button,
.jump-to-page input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 768px) {
  .transaction-controls {
    flex-direction: column;
    gap: 12px;
  }
  
  .pagination-controls {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
} 