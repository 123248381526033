.chart-analysis {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.chart-analysis h2 {
  margin-bottom: 20px;
  color: #ffffff;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.stat-card {
  background-color: #3a3a3a;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
}

.stat-card h3 {
  color: #808080;
  margin-bottom: 10px;
}

.stat-number {
  font-size: 24px;
  font-weight: bold;
}

.stat-number.green { color: #4caf50; }
.stat-number.red { color: #f44336; }
.stat-number.blue { color: #2196f3; } 