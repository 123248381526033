.filter-bar {
  background-color: #2a2a2a;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.time-filters {
  display: flex;
  gap: 12px;
}

.filter-button {
  background-color: #3a3a3a;
  border: none;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.filter-button:hover {
  background-color: #4a4a4a;
}

.filter-button.active {
  background-color: #5d80f9;
} 