.rankings-container {
  padding: 20px;
}

.rankings-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.rankings-column {
  background: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
}

.rankings-column h2 {
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.5rem;
}

.token-card {
  display: flex;
  background: #3a3a3a;
  margin: 10px 0;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.token-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.rank {
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 15px;
  color: #5d80f9;
  min-width: 40px;
  text-align: center;
}

.token-info {
  flex: 1;
}

.token-info h3 {
  margin: 0 0 5px 0;
  color: #ffffff;
  font-size: 1.1em;
}

.token-info p {
  margin: 3px 0;
  color: #b0b0b0;
  font-size: 0.9em;
}

.rankings-loading,
.rankings-error {
  text-align: center;
  padding: 20px;
  color: #b0b0b0;
  background: #2a2a2a;
  border-radius: 8px;
  margin: 20px;
}

.rankings-error {
  color: #ff6b6b;
}

@media (max-width: 768px) {
  .rankings-grid {
    grid-template-columns: 1fr;
  }
  
  .rankings-column {
    margin-bottom: 20px;
  }
}

.loading-spinner {
  border: 4px solid #3a3a3a;
  border-top: 4px solid #5d80f9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.label {
  color: #808080;
  margin-right: 8px;
}

.value {
  color: #ffffff;
  font-weight: 500;
}

.no-data {
  text-align: center;
  color: #808080;
  padding: 20px;
  background: #3a3a3a;
  border-radius: 6px;
  margin: 10px 0;
}

.retry-button {
  background: #5d80f9;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.2s;
}

.retry-button:hover {
  background: #4a6de0;
} 