.token-details-container {
  padding: 20px;
  background-color: #1a1a1a;
  min-height: 100vh;
}

.token-info-header {
  background: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.token-info-header h1 {
  color: #ffffff;
  margin: 0 0 20px 0;
}

.token-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.stat-item {
  background: #3a3a3a;
  padding: 15px;
  border-radius: 6px;
}

.stat-item label {
  display: block;
  color: #808080;
  margin-bottom: 5px;
}

.stat-item span {
  color: #ffffff;
  font-size: 1.2em;
  font-weight: 500;
}

.token-filters {
  display: flex;
  gap: 10px;
}

.token-filters select {
  background: #3a3a3a;
  color: white;
  border: 1px solid #4a4a4a;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.token-filters select:hover {
  border-color: #5d80f9;
}

.token-details-loading,
.token-details-error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #ffffff;
}

.token-details-error {
  color: #ff6b6b;
}

@media (max-width: 768px) {
  .token-stats {
    grid-template-columns: 1fr;
  }
  
  .token-filters {
    flex-direction: column;
  }
} 