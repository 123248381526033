.App {
  background-color: #1a1a1a;
  min-height: 100vh;
  padding: 20px;
  color: white;
  max-width: 1400px;
  margin: 0 auto;
}

.grid-container {
  margin: 20px 0;
}

.loading, .error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 1.5rem;
  color: white;
  text-align: center;
}

.error {
  color: #f44336;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }
  
  .grid-container {
    margin: 10px 0;
  }
} 